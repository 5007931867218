<template>
  <div class="about">
    <h1 @click="changeLanguage">This is an about page</h1>
    <div>current language {{ this.$t("test") }}</div>
  </div>
</template>
<script>
import { setLocales } from "@/utils/lang";

export default {
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: "test" });
    }, 2000);
  },

  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    next();
  },

  methods: {
    changeLanguage() {
      this.$router.push("/");
    },
  },
};
</script>